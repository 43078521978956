import React from "react";

// import logo from "../assets/logo.svg";

import pic1 from "../assets/img/14H05302_RM_retina.jpg"
import diagram from "../assets/diagram.png"

const Hero = () => (
  <div className="text-center">
    <img alt="" src={pic1} width="100%"/>
    {/*<div id="bgroundimg"></div>*/}
    <div className="spaHeading"><h1 className="mb-4">React.js Sample Project</h1></div>

    <p className="lead">
      This is a sample application that demonstrates an authentication flow for
      an SPA, using React.js
    </p>
      <div className="mb-5"><img alt="" src={diagram} width="100%"></img></div>
  </div>
);

export default Hero;
