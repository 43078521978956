import React from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import ApiResult from "../components/ApiResult"
import { useAuth0 } from "../react-auth0-spa";

const Profile = () => {
  const { loading, user, getTokenSilently, getTokenWithPopup } = useAuth0();

  const fetchToken = async () => {
      const token = await getTokenWithPopup({
          audience: 'https://z0kps0l06h.execute-api.us-east-2.amazonaws.com/prod/'
      });
      console.log("token is " + token);
      localStorage.setItem("token", token);
  };

  if (loading || !user) {
    return <Loading />;
  } else {
      fetchToken();
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Col>
      </Row>
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
      <Row>
        <ApiResult/>
      </Row>
    </Container>
  );
};

export default Profile;
