import React from "react";

import screencast from "../assets/screencast.mp4"

const VideoPlayer = () => (
    <div className="text-center">
        <video controls>
            <source src={screencast} type="video/mp4" />
                    Your browser does not support this video player.
        </video>
    </div>
);

export default VideoPlayer;