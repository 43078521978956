import React, { Component } from "react";
import {useAuth0} from "../react-auth0-spa";

class ApiResult extends Component {
    constructor() {
        super();
        this.state = {
            apiResult: [],
        }
    }

    componentDidMount() {
        fetch('https://z0kps0l06h.execute-api.us-east-2.amazonaws.com/prod', {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem("token")
            }
        })
            .then((response) => {
                return response.json();
                // console.log(response.json());
            })
            .then(data => {
                let result = data.message;
                this.setState({apiResult: result});
            });
    }

    render() {
        return (
            <p style={{width: '90%'}}>{this.state.apiResult}</p>
        )
    }

}

export default ApiResult;