import React, { Fragment } from "react";

import Hero from "../components/Hero";
// import Content from "../components/Content";
import VideoPlayer from "../components/VideoPlayer";

const Home = () => (
    <Fragment>
        <VideoPlayer/>
        <hr />
        <Hero />
        {/*<hr />*/}
        {/*<Content />*/}
    </Fragment>
);

export default Home;
